<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.tournaments') }}</h5>
                <DataTable
                    v-show="filtersInitializationDone"
                    :lazy="true"
                    :value="items"
                    :paginator="true"
                    :rows="perPage"
                    :loading="loading"
                    class="p-mt-3"
                    :totalRecords="totalPages"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"
                    ref="datatable"
                    responsiveLayout="scroll"
                    :paginatorBottom="true"
                    stateStorage="session"
                    stateKey="dt-state-tournaments"
                >
                    <template #header>
                        <div class="p-grid">
                            <div class="p-input-icon-left p-col-12 p-md-2">
                                <i class="pi pi-search" />
                                <InputText @input="search" v-model="searchTerm" :placeholder="$t('search.byName')" style="width: 100%" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown :showClear="true" style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="displayName" :placeholder="$t('search.bySport')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown :showClear="true" style="width: 100%" v-model="categoriesModel" :options="filteredCategories" optionLabel="displayName" :placeholder="$t('search.byCategory')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2 p-md-offset-4 p-text-right"><Button :label="$t('buttons.add')" class="p-button-raised p-button-success" @click="goto(editPage, { id: 0 })" /></div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column field="id" :header="$t('sportsSettings.id')" :sortable="true"></Column>
                    <Column field="name" :header="$t('sportsSettings.name')" :sortable="true"></Column>
                    <Column field="displayName" :header="$t('sportsSettings.displayName')" :sortable="true"></Column>
                    <Column :header="$t('sportsSettings.sport')">
                        <template #body="slotProps"> {{ sportsDictionary[slotProps.data.sportId] }}</template></Column
                    >
                    <Column :header="$t('sportsSettings.category')">
                        <template #body="slotProps"> {{ categoriesDictionary[slotProps.data.categoryId] }}</template></Column
                    >
                    <Column field="marketTemplate" :header="$t('sportsSettings.marketTemplate')" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.marketTemplate != null ? marketTemplatesDictionary[slotProps.data.marketTemplate] : '' }}</template></Column
                    >
                    <Column field="oddsTemplate" :header="$t('sportsSettings.oddsTemplate')" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.oddsTemplate != null ? oddsTemplatesDictionary[slotProps.data.oddsTemplate] : '' }}</template></Column
                    >
                    <Column field="printTemplate" :header="$t('sportsSettings.printTemplate')" :sortable="true">
                        <template #body="slotProps"> {{ slotProps.data.printTemplate != null ? printTemplatesDictionary[slotProps.data.printTemplate] : '' }}</template></Column
                    >
                    <Column field="sortOrder" :header="$t('sportsSettings.sortOrder')" :sortable="true"></Column>
                    <Column :exportable="false" class="p-text-right">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="goto(editPage, { id: slotProps.data.id })" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
import { uiSettings } from '../../settings/uiSettings';
import { store } from '../../store/index';
import { debounce } from 'lodash';
export default {
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            SportsSettingsApi: new SportsSettingsApi(),
            searchTerm: '',
            sort: '',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalPages: 0,
            crtRoute: 'tournaments',
            sports: [],
            sportsDictionary: {},
            sportsModel: {},
            categories: [],
            categoriesDictionary: {},
            categoriesModel: {},
            defaultSportsModel: { displayName: 'All', id: '0' },
            defaultCategoriesModel: { displayName: 'All', id: '0' },
            filtersInitializationDone: false,
            marketTemplatesDictionary: {},
            printTemplatesDictionary: {},
            oddsTemplatesDictionary: {},
        };
    },
    computed: {
        storeFilterData() {
            return store.getters['filters/filter'](this.crtRoute);
        },
        editPage() {
            return this.crtRoute + '/edit';
        },
        filteredCategories() {
            if (this.sportsModel == undefined || this.sportsModel.id == '0') {
                return this.categories;
            } else {
                return this.categories.filter((c) => c.sportId == this.sportsModel.id);
            }
        },
    },
    mounted() {
        this.getAllTemplates();
        this.getAllCategories();
        this.SportsSettingsApi.getAllSports()
            .then((response) => {
                this.sports = response.data;
                this.sports.unshift(this.defaultSportsModel);
                this.createSportsDictionary();
                if (this.storeFilterData != undefined) {
                    this.searchTerm = this.storeFilterData.data.search;
                    this.sort = this.storeFilterData.data.sort;
                    this.sportsModel = this.sports.find((s) => s.id == this.storeFilterData.data.sportId);
                    this.readItems(this.storeFilterData.data.pageNo);
                } else {
                    this.readItems();
                }
            })
            .catch(() => {});
    },
    watch: {
        sportsModel() {
            if (this.filtersInitializationDone) {
                this.composeAndSaveFiltersToStore();
                this.readItems();
            }
        },
        categoriesModel() {
            if (this.filtersInitializationDone) {
                this.composeAndSaveFiltersToStore();
                this.readItems();
            }
        },
    },
    methods: {
        readItems(page = 1) {
            this.crtPage = page;
            this.loading = true;
            let params = {
                per_page: this.perPage,
                page: page,
                search: this.searchTerm,
                sort: this.sort,
                sportId: this.notNullOrEmptyObject(this.sportsModel) ? this.sportsModel.id : this.defaultSportsModel.id,
                categoryId: this.notNullOrEmptyObject(this.categoriesModel) ? this.categoriesModel.id : this.defaultCategoriesModel.id,
            };
            this.SportsSettingsApi.getTournaments(params).then((response) => {
                this.items = response.data.data;
                this.totalPages = response.data.total;
                this.loading = false;
                this.filtersInitializationDone = true;
            });
        },
        onPage(event) {
            this.readItems(event.page + 1);
            this.composeAndSaveFiltersToStore();
        },
        onSort(event) {
            let sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.sort = event.sortField + '|' + sortOrder;
            this.readItems();
            this.composeAndSaveFiltersToStore();
        },
        composeAndSaveFiltersToStore() {
            let filters = {
                pageNo: this.crtPage,
                search: this.searchTerm,
                sort: this.sort,
                sportId: this.notNullOrEmptyObject(this.sportsModel) ? this.sportsModel.id : this.defaultSportsModel.id,
                categoryId: this.notNullOrEmptyObject(this.categoriesModel) ? this.categoriesModel.id : this.defaultCategoriesModel.id,
            };
            this.saveFiltersToStore(filters, this.crtRoute);
        },
        search: debounce(function () {
            this.composeAndSaveFiltersToStore();
            this.readItems();
        }, 400),
        createSportsDictionary() {
            for (let i = 0; i < this.sports.length; i++) {
                this.sportsDictionary[this.sports[i].id] = this.sports[i].displayName;
            }
        },
        createCategoriesDictionary() {
            for (let i = 0; i < this.categories.length; i++) {
                this.categoriesDictionary[this.categories[i].id] = this.categories[i].displayName;
            }
        },
        getAllCategories() {
            this.SportsSettingsApi.getAllCategories('0').then((response) => {
                this.categories = response.data;
                this.createCategoriesDictionary();
                this.categories.unshift(this.defaultCategoriesModel);
                if (this.storeFilterData != undefined) {
                    this.categoriesModel = this.categories.find((s) => s.id == this.storeFilterData.data.categoryId);
                }
            });
        },
        getAllTemplates() {
            this.SportsSettingsApi.getMarketTemplates('0').then((marketResponse) => {
                for (let i = 0; i < marketResponse.data.length; i++) {
                    this.marketTemplatesDictionary[marketResponse.data[i]._id] = marketResponse.data[i].name;
                }
            });
            this.SportsSettingsApi.getPrintTemplates().then((printTemplateResponse) => {
                for (let i = 0; i < printTemplateResponse.data.length; i++) {
                    this.printTemplatesDictionary[printTemplateResponse.data[i]._id] = printTemplateResponse.data[i].name;
                }
            });
            this.SportsSettingsApi.getOddsTemplates().then((oddsTemplateResponse) => {
                for (let i = 0; i < oddsTemplateResponse.data.length; i++) {
                    this.oddsTemplatesDictionary[oddsTemplateResponse.data[i]._id] = oddsTemplateResponse.data[i].name;
                }
            });
        },
    },
};
</script>
